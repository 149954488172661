/* eslint-disable no-unused-vars */
import { $t } from '@/i18n/index'
import { groupBy, sortBy } from 'lodash-es'
import { useAccountStore } from './accounts'
import { CurrencyItemType } from '#/ReportsTypes'
import TransactionsApi from '@/api/TransactionsApi'
import BusinessDataApi from '@/api/BusinessDataApi'
import { AutomationRuleListType, SystemTrxAutomationRuleItemType } from '#/EntityTypes'
import {
  TradeListType,
  JournalListType,
  GainLossListType,
  JournalFilterType,
  TransactionListType,
  JournalTypeItemType,
  JournalGroupListType,
  JournalFilterItemType,
  TransactionFilterType,
  JournalGroupFilterType,
  JournalGroupFilterItemType,
  JournalGroupBusinessEventType,
  TransactionFilterTypeInfo
} from '#/TransactionsTypes'
import { transactionFilterSaveType } from '@/config/transactions'
import LedgerApi from '@/api/LedgerApi'

export const useTransactionStore = defineStore({
  id: 'transactionStore',
  state: () => {
    const transactionState: {
      journalList: JournalListType
      journalGroupList: JournalGroupListType
      transactionList: TransactionListType
      journalTypeList: JournalTypeItemType[]
      automationRuleList: AutomationRuleListType
      systemAutomationRuleList: SystemTrxAutomationRuleItemType[]
      tradeAutomationRuleList: AutomationRuleListType
      gainLossAutomationRuleList: AutomationRuleListType
      businessDataAutomationRuleList: AutomationRuleListType
      derivativeFactTransformerList: AutomationRuleListType
      counterpartyTransformerList: AutomationRuleListType
      transactionCurrencyList: CurrencyItemType[]
      businessEventList: JournalGroupBusinessEventType[]
      entityFilterList: TransactionFilterTypeInfo[]
      entityJournalFilterList: JournalFilterItemType[]
      entityJournalGroupFilterList: JournalGroupFilterItemType[]
      tradeList: TradeListType
      gainLossList: GainLossListType
    } = {
      journalList: {
        total: 0,
        list: []
      },
      journalGroupList: {
        total: 0,
        list: [],
        datetime: ''
      },
      transactionList: {
        total: {
          amountInFC: 0,
          amountOutFC: 0
        },
        totalCount: 0,
        filteredCount: 0,
        list: []
      },
      journalTypeList: [],
      automationRuleList: {
        totalCount: 0,
        list: []
      },
      systemAutomationRuleList: [],
      tradeAutomationRuleList: {
        totalCount: 0,
        list: []
      },
      gainLossAutomationRuleList: {
        totalCount: 0,
        list: []
      },
      businessDataAutomationRuleList: {
        totalCount: 0,
        list: []
      },
      derivativeFactTransformerList: {
        totalCount: 0,
        list: []
      },
      counterpartyTransformerList: {
        totalCount: 0,
        list: []
      },
      businessEventList: [],
      transactionCurrencyList: [],
      entityFilterList: [],
      entityJournalFilterList: [],
      entityJournalGroupFilterList: [],
      tradeList: {
        totalCount: 0,
        filteredCount: 0,
        list: [],
        total: {
          counterAmountFC: 0,
          feeAmountFC: 0
        }
      },
      gainLossList: {
        totalCount: 0,
        filteredCount: 0,
        list: [],
        total: {
          counterAmountFC: 0,
          feeAmountFC: 0
        }
      }
    }
    return transactionState
  },
  actions: {
    /**
     * 获取Journal列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     * @property {string} sortField
     * @property {string} sortDirection
     */
    async fetchJournalList(entityId: string, params: object) {
      try {
        const response = await LedgerApi.getJournalList(entityId, params)
        this.journalList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Journal分组列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     */
    async fetchJournalGroupList(entityId: string, params: object) {
      try {
        this.journalGroupList.list = []
        const response = await LedgerApi.getJournalGroupList(entityId, params)
        response.data.list = response.data.list.filter((item: any) => item.journalActivityList.length)
        response.data.list.forEach((item: any) => {
          item.journalActivityList?.forEach((secondItem: any) => {
            // eslint-disable-next-line no-param-reassign
            secondItem.entryList = secondItem.entryList?.length
              ? sortBy(secondItem.entryList, (i: any) => {
                  // Dr在前Cr在后
                  if (i.balanceType === 'Dr') {
                    return i?.chartOfAccount?.slug === 'gst_output' || i?.chartOfAccount?.slug === 'gst_input' ? 1 : 0
                  }
                  if (i.balanceType === 'Cr') {
                    return i?.chartOfAccount?.slug === 'gst_output' || i?.chartOfAccount?.slug === 'gst_input' ? 3 : 2
                  }
                  return 0
                })
              : []
          })
        })
        this.journalGroupList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Business Event列表
     * @param {string} entityId 主体id
     */
    async fetchJournalGroupBusinessEventList(entityId: string) {
      try {
        const response = await TransactionsApi.getJournalGroupBusinessEventList(entityId)
        this.businessEventList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取journalType列表
     * @param {string} entityId 主体id
     */
    async fetchJournalTypeList(entityId: string) {
      try {
        const response = await TransactionsApi.getJournalTypeList(entityId)
        this.journalTypeList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Transaction列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     * @property {string} sortField
     * @property {string} sortDirection
     */
    async fetchTransactionList(entityId: string, params: object) {
      try {
        const response = await TransactionsApi.getTransactionList(entityId, params)
        this.transactionList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Trx规则列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page
     * @property {number} params.limit
     */
    async fetchTrxAutomationRuleList(entityId: string, params: object) {
      try {
        const response = await TransactionsApi.getTrxAutomationRuleList(entityId, params)
        this.automationRuleList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Trx系统规则列表
     * @param {string} entityId 主体id
     */
    async fetchTrxSystemAutomationRuleList(entityId: string) {
      try {
        const response = await TransactionsApi.getTrxSystemAutomationRuleList(entityId)
        this.systemAutomationRuleList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Trade规则列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page
     * @property {number} params.limit
     */
    async fetchTradeAutomationRuleList(entityId: string, params: object) {
      try {
        const response = await TransactionsApi.getTradeAutomationRuleList(entityId, params)
        this.tradeAutomationRuleList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取GainLoss规则列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page
     * @property {number} params.limit
     */
    async fetchGainLossAutomationRuleList(entityId: string, params: object) {
      try {
        const response = await TransactionsApi.getGainLossAutomationRuleList(entityId, params)
        this.gainLossAutomationRuleList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取业务数据规则列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page
     * @property {number} params.limit
     */
    async fetchBusinessDataAutomationRuleList(entityId: string, params: object) {
      try {
        const response = await BusinessDataApi.getBusinessDataAutomationRuleList(entityId, params)
        this.businessDataAutomationRuleList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取businessData生成Valuation规则列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page
     * @property {number} params.limit
     */
    async fetchDerivativeFactTransformerList(entityId: string, params: object) {
      try {
        const response = await TransactionsApi.getDerivativeFactTransformerRuleList(entityId, params)
        this.derivativeFactTransformerList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取CounterpartyTransformer规则列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page
     * @property {number} params.limit
     */
    async fetchCounterpartyTransformerList(entityId: string, params: object) {
      try {
        const response = await TransactionsApi.getCounterpartyTransformerRuleList(entityId, params)
        this.counterpartyTransformerList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Transaction币种列表
     * @param {string} entityId 主体id
     */
    async fetchTransactionCurrencyList(entityId: string) {
      try {
        const { data } = await TransactionsApi.getTransactionCurrencyList(entityId)
        this.transactionCurrencyList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 交易列表筛选器数据初始化
     * @param {string} entityId 主体id
     * @param {string} type Transfer/Trade/GainLoss
     */
    async transactionFilterDataInit(entityId: string, type: string = 'Transfer') {
      const accountStore = useAccountStore()
      try {
        const requestList: any = [accountStore.fetchEntityAccountList(entityId, { withDeleted: true })]
        if (type === 'Transfer') {
          requestList.push(this.fetchTransactionCurrencyList(entityId))
        }
        const response = await Promise.all(requestList)
        return response
      } catch (error: any) {
        return Promise.reject(error)
      }
    },
    /**
     * 编辑交易列表筛选器
     * @param {number} entityId 主体id
     * @param {TransactionFilterType} data 过滤器数据
     * @param {string} type 过滤器类型 transactionFilterSaveType
     */
    editEntityFilter(entityId: string, data: TransactionFilterType, currentType = transactionFilterSaveType.TRANSFER) {
      const currentSaveInfo = this.entityFilterList.find((item) => item.type === currentType)
      const filterLength = Object.keys(data).length
      if (currentSaveInfo) {
        const currentSaveList = currentSaveInfo?.list || []
        const currentIndex = currentSaveList.findIndex((item: any) => item.entityId === entityId)

        if (currentIndex !== -1) {
          if (!filterLength) {
            currentSaveList.splice(currentIndex, 1)
          } else {
            currentSaveList.splice(currentIndex, 1, {
              entityId,
              total: filterLength,
              data
            })
          }
        } else if (filterLength) {
          currentSaveList.push({ entityId, total: filterLength, data })
        }
      } else {
        this.entityFilterList.push({
          type: currentType,
          list: [
            {
              entityId,
              total: filterLength,
              data
            }
          ]
        })
      }
    },
    /**
     * 编辑Journal列表筛选器
     * @param {number} entityId 主体id
     * @param {JournalFilterType} data 过滤器数据
     */
    editEntityJournalFilter(entityId: string, data: JournalFilterType) {
      const currentIndex = this.entityJournalFilterList.findIndex((item: any) => item.entityId === entityId)
      let filterLength = Object.keys(data).length
      if (data?.journalTypeIds?.length && data.showSystem !== undefined) {
        filterLength -= 1
      }
      if (
        data?.businessDataSourceId?.length &&
        data?.externalJournalSourceId?.length &&
        data?.sourceId?.length &&
        data?.source?.length &&
        data?.isManual !== undefined
      ) {
        filterLength -= 3
      } else if (
        (data?.source?.length && data?.businessDataSourceId?.length && data?.sourceId?.length) ||
        (data?.source?.length && data?.externalJournalSourceId?.length && data?.sourceId?.length) ||
        (data?.source?.length && data?.businessDataSourceId?.length && data?.externalJournalSourceId?.length) ||
        (data?.source?.length && data?.businessDataSourceId?.length && data?.isManual !== undefined) ||
        (data?.source?.length && data?.externalJournalSourceId?.length && data?.isManual !== undefined) ||
        (data?.source?.length && data?.sourceId?.length && data?.isManual !== undefined)
      ) {
        filterLength -= 2
      } else if (
        (data?.source?.length && data?.businessDataSourceId?.length) ||
        (data?.source?.length && data?.externalJournalSourceId?.length) ||
        (data?.source?.length && data?.isManual !== undefined) ||
        (data?.source?.length && data?.sourceId?.length)
      ) {
        filterLength -= 1
      }

      if (currentIndex !== -1) {
        if (!filterLength) {
          this.entityJournalFilterList.splice(currentIndex, 1)
        } else {
          this.entityJournalFilterList.splice(currentIndex, 1, {
            entityId,
            total: filterLength,
            data
          })
        }
      } else if (filterLength) {
        this.entityJournalFilterList.push({ entityId, total: filterLength, data })
      }
    },
    /**
     * 编辑Journal分组列表筛选器
     * @param {number} entityId 主体id
     * @param {JournalGroupFilterType} data 过滤器数据
     */
    editEntityJournalGroupFilter(entityId: string, data: JournalGroupFilterType) {
      const currentIndex = this.entityJournalGroupFilterList.findIndex((item: any) => item.entityId === entityId)
      const filterLength = Object.keys(data).length

      if (currentIndex !== -1) {
        if (!filterLength) {
          this.entityJournalGroupFilterList.splice(currentIndex, 1)
        } else {
          this.entityJournalGroupFilterList.splice(currentIndex, 1, {
            entityId,
            total: filterLength,
            data
          })
        }
      } else if (filterLength) {
        this.entityJournalGroupFilterList.push({ entityId, total: filterLength, data })
      }
    },
    /**
     * 规则自动化数据初始化
     * @param {string} entityId 主体id
     * @param {string} type
     */
    async autoMationRuleDataInit(entityId: string, type: string) {
      const accountStore = useAccountStore()
      try {
        const requestList: any = [
          this.fetchJournalTypeList(entityId),
          accountStore.fetchEntityAccountList(entityId, { withDeleted: true }),
          accountStore.fetchCounterpartList(entityId, { page: 1, limit: 20 })
        ]
        if (type !== 'derivativeAdjustment') {
          requestList.push(accountStore.fetchCounterpartTagList(entityId))
        }
        const response = await Promise.all(requestList)
        return response
      } catch (error: any) {
        return Promise.reject(error)
      }
    },

    /**
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     * @property {string} sortField
     * @property {string} sortDirection
     */
    async fetchTradeList(entityId: string, params: object) {
      try {
        const response = await TransactionsApi.getTradeTableList(entityId, params)
        this.tradeList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     * @property {string} sortField
     * @property {string} sortDirection
     */
    async fetchGainLossList(entityId: string, params: object) {
      try {
        const response = await TransactionsApi.getGainLossTableList(entityId, params)
        this.tradeList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  getters: {
    /**
     * 获取journalType下拉菜单筛选列表
     * @param {string} dataSize all || in || out || filter || 'journal' || 'automation'
     * @return {Function}
     */
    journalTypeFilterList:
      (state): Function =>
      (dataSize: string = 'filter') => {
        // eslint-disable-next-line no-shadow
        enum JournalTypeGroup {
          OPERATING = 'operating',
          FINANCING = 'financing',
          INVESTING = 'investing',
          INTERNAL_TRANSFER = 'internalTransfer',
          GAIN_OR_LOSS_ADJUSTING = 'gainOrLossAdjusting',
          GENERAL_JOURNAL = 'generalJournal'
        }
        const journalTypeFilterList: any[] = []
        type JournalType = {
          group: string
          slug: string
          entryType: string
        }

        function filterJournalTypeList() {
          if (dataSize === 'all') {
            return state.journalTypeList
          }

          const validDataSizes = ['IN', 'OUT', 'journal', 'automation']
          const invalidSlugs = ['internal_transfer', 'buy_token', 'sell_token', 'token_trade']

          return state.journalTypeList.filter(
            (item) =>
              (item.group !== 'GAIN_OR_LOSS_ADJUSTING' &&
                validDataSizes.includes(dataSize) &&
                !invalidSlugs.includes(item.slug)) ||
              !validDataSizes.includes(dataSize)
          )
        }

        let journalTypeList: any = filterJournalTypeList()
        if (dataSize === 'automation') {
          journalTypeList = journalTypeList.filter((item: any) => item.entryType !== 'COMPOUND')
        }
        const journalTypeGroup = groupBy(journalTypeList, 'group')
        for (const key in journalTypeGroup || dataSize === 'all') {
          if (key !== 'GENERAL_JOURNAL') {
            journalTypeFilterList.push({
              groupName: $t(`report.${JournalTypeGroup[key as keyof typeof JournalTypeGroup]}`),
              group: key,
              list: journalTypeGroup[key]
            })
          }
        }
        return journalTypeFilterList
      }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ['entityFilterList', 'entityJournalFilterList', 'entityJournalGroupFilterList']
      }
    ]
  }
})
